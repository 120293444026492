/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
//Sprachbiblotheken
import { de } from 'vuetify/locale'

// Composables
import { createVuetify } from 'vuetify'
import { VDateInput } from 'vuetify/labs/VDateInput'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    VDateInput
  },
  locale: {
    messages: { de },
  },
  theme: {
    themes: {
      DiestelmannITGmbH: {
        colors: {
          primary: "#b7e3ef",
          secondary: "#5793bf",
          noColor: '#ffffff',

        },
      },
      WasserwerkePaderborn: {
        colors: {
          primary: "#0067a5",
          secondary: "#5793bf",
          noColor: '#ffffff',

        },
      },

      NEWNetzGmbH: {
        colors: {
          primary: '#840055',
          secondary: '#5CBBF6',
          background: 'F2F2F3'
        }
      },
      SWKampLintfort: {
        colors: {
          background: '#FFFFFF',
          primary: '#da291c',
          secondary: '#FFD200',
          thirt: '#00AEEF'
        }
      },
      StadtwerkeBochum: {
        colors: {
          background: '#FFFFFF',
          primary: '#da291c',
          secondary: '#FFD200',
          thirt: '#00AEEF'

        }
      },

    },
  },



})